var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.userOperation.userOperationImagePath,
            _vm.userOperation.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.userOperation.userOperationImageIsDefault &&
          _vm.hasPrivilegeDeleteImage},on:{"changeValue":function($event){_vm.userOperation.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.userOperation.fullCode,"title":_vm.$t('UserOperations.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.userOperation.fullCode = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-operationTypeToken"),"errors":_vm.errors_operationTypeToken,"value":_vm.userOperation.operationTypeToken,"options":_vm.operationTypeTokenOptions,"title":_vm.$t('OperationTypes.select'),"imgName":'operationTypes.svg'},on:{"changeValue":function($event){_vm.userOperation.operationTypeToken = $event;
            _vm.$v.userOperation.operationTypeToken.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-bodyPartToken"),"errors":_vm.errors_bodyPartToken,"value":_vm.userOperation.bodyPartToken,"options":_vm.bodyPartTokenOptions,"title":_vm.$t('BodyParts.select'),"imgName":'bodyParts.svg'},on:{"changeValue":function($event){_vm.userOperation.bodyPartToken = $event;
            _vm.$v.userOperation.bodyPartToken.$touch();}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-operationDateTime"),"type":"dateTime","value":_vm.userOperation.operationDateTime,"title":_vm.$t('UserOperations.operationDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.userOperation.operationDateTime = $event.dateTime}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userOperationDescriptionAr"),"errors":_vm.errors_userOperationDescriptionAr,"value":_vm.userOperation.userOperationDescriptionAr,"title":_vm.$t('UserOperations.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userOperation.userOperationDescriptionAr = $event;
            _vm.$v.userOperation.userOperationDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userOperationDescriptionEn"),"errors":_vm.errors_userOperationDescriptionEn,"value":_vm.userOperation.userOperationDescriptionEn,"title":_vm.$t('UserOperations.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userOperation.userOperationDescriptionEn = $event;
            _vm.$v.userOperation.userOperationDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userOperationDescriptionUnd"),"value":_vm.userOperation.userOperationDescriptionUnd,"title":_vm.$t('UserOperations.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userOperation.userOperationDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userOperationNotes"),"value":_vm.userOperation.userOperationNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.userOperation.userOperationNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }